import axios from "axios";
import { config } from ".";

// A new instance of axios
const instance = axios.create({
  baseURL: config.restapi_endpoint,
});

export default class RESTAPI {
  /**
   * Get data about leaderboards for the game list
   *
   * @returns void
   */
  static async getLeaderboards() {
    try {
      const { data } = await instance.get("game/leaderboard");
      return data;
    } catch ({ message }) {
      return { status: "error", message };
    }
  }

  static async AddNickname(_data) {
    try {
      const { data } = await instance.post("wallet/nickname", _data);
      return data;
    } catch ({ message }) {
      return { status: "fail", message };
    }
  }
}
