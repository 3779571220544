import React from "react";

export const WalletContext = React.createContext({});

export class WalletContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      wallet_address: false,
      showModal: false,
      setWallet: this.setWallet.bind(this),
      toggleModal: this.toggleModal.bind(this),
    };
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  setWallet(wallet_address) {
    this.setState({ wallet_address });
  }

  render() {
    return (
      <WalletContext.Provider value={this.state}>
        {this.props.children}
      </WalletContext.Provider>
    );
  }
}
