import { useContext } from "react";
import logo from "../../assets/images/logo.png";
import { WalletContext } from "../../config";

const TableScore = ({ gameName, scores }) => {
  const state = useContext(WalletContext);

  return (
    <div className="container mt-5 px-3">
      <div className="is-flex is-justify-content-space-between is-align-items-center">
        <h2 className="is-size-3 has-text-white mb-3">
          <img className="logo" src={logo} alt={gameName} />
        </h2>

        <div className="mb-3">
          <a
            target="_blank"
            rel="noreferrer"
            className="twitter-share-button"
            href="https://twitter.com/intent/tweet?text=Check%20out%20the%20%23FadedArcade%20and%20the%20cool%20people%20behind%20it%20-%20Hamster%20Jamz%20Leaderboard&url=https%3A%2F%2Fleaderboard.hamsterjamz.com%2F"
          >
            Share On Twitter
          </a>
        </div>
      </div>

      <div className="table-container">
        <table className="table is-fullwidth">
          <thead className="">
            <tr>
              <th className="p-3 has-text-white">Position</th>
              <th className="p-3 has-text-white">Score</th>
              <th className="p-3 has-text-white">
                Nickname
                <button
                  className="button is-small is-link ml-3"
                  onClick={state.toggleModal}
                >
                  Select Your Nickname
                </button>
              </th>
            </tr>
          </thead>

          <tbody className="">
            {scores.length > 0 ? (
              scores.map(({ score, address, nickname }, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{score}</td>
                  <td>{nickname ? nickname : address}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No Results</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableScore;
