import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import React from "react";
import { RESTAPI, rules, WalletContext } from "../../config";
import Alert from "../alert";
import ConnectWallet from "../connect_wallet";

class Nickname extends React.Component {
  constructor() {
    super();

    this.state = {
      status: false,
      alert: false,
    };

    this.initialValues = {
      nickname: "",
    };
  }

  async handleSubmit(values, actions) {
    this.setState({ status: true });

    const { wallet_address } = this.context;
    const data = await RESTAPI.AddNickname({ ...values, wallet_address });

    this.setState({
      status: false,
      alert: { type: data.status, message: data.message },
    });

    if (data.status !== "fail") {
      actions.resetForm();
    }
  }

  closeModal() {
    this.context.toggleModal();
  }

  render() {
    const { status, alert } = this.state;
    const { showModal, wallet_address } = this.context;

    if (!showModal) {
      return null;
    }

    return (
      <div
        className="nickname-modal"
        onClick={(e) =>
          e.target.classList.contains("nickname-modal") && this.closeModal()
        }
      >
        <div className="nickname-content">
          <button
            className="modal-close"
            onClick={this.closeModal.bind(this)}
          ></button>

          <div className="nickname-header">
            <h4 className="mb-3">Add Nickname to wallet address</h4>

            <article className="message is-small">
              <div className="message-body">{wallet_address}</div>
            </article>
          </div>

          <div className="nickname-body">
            {!wallet_address ? (
              <ConnectWallet />
            ) : (
              <Formik
                initialValues={this.initialValues}
                onSubmit={this.handleSubmit.bind(this)}
              >
                {({ errors, isSubmitting }) => (
                  <Form>
                    {alert && <Alert {...alert} />}

                    <div className="field">
                      <Field
                        name="nickname"
                        className={classNames("input", {
                          "is-danger": errors.nickname,
                        })}
                        validate={rules.isEmpty}
                      />
                    </div>

                    <div className="field has-text-centered">
                      <button
                        type="submit"
                        className={classNames("button", {
                          "is-loading": status,
                        })}
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Nickname.contextType = WalletContext;

export default Nickname;
