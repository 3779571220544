import React from "react";
import { ethers } from "ethers";
import classNames from "classnames";
import { config, Helper, WalletContext, _web3Modal } from "../../config";
import Alert from "../alert";

class ConnectWallet extends React.Component {
  constructor() {
    super();

    this.state = {
      alert: false,
      connected: false,
      wallet_address: false,
    };
  }

  async handleConnect() {
    this.setState({ alert: false, submit: true });

    const instance = await _web3Modal.connect();

    const provider = new ethers.providers.Web3Provider(instance);
    const network_id = await provider.send("net_version");

    if (parseInt(network_id) !== parseInt(config.network)) {
      this.setState({
        alert: {
          message: `Change network to ${Helper.networkName(config.network)}.`,
        },
      });

      return false;
    }

    const signer = provider.getSigner();
    const wallet_address = await signer.getAddress();

    this.setState({ wallet_address });
    this.context.setWallet(wallet_address);
  }

  componentDidMount() {
    this.handleConnect();
  }

  render() {
    const { alert, wallet_address } = this.state;

    if (wallet_address) {
      return null;
    }

    return (
      <div className="is-flex is-flex-direction-column">
        {alert && <Alert {...alert} />}

        <div className="has-text-centered">
          <button
            className={classNames("button", "is-link", "mb-3")}
            onClick={this.handleConnect.bind(this)}
          >
            Connect Wallet
          </button>
        </div>
      </div>
    );
  }
}

ConnectWallet.contextType = WalletContext;

export default ConnectWallet;
