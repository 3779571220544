import config from "./config.json";

import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import validator from "validator";

const _web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: config.infuraId, // required
      },
    },
  },
});

const rules = {
  isEmpty: (value) => validator.isEmpty(value),
};

export { WalletContext, WalletContextProvider } from "./context";
export { default as RESTAPI } from "./restapi";
export { default as Helper } from "./helper";
export { config, _web3Modal, rules };
