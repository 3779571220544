import { useEffect, useState } from "react";
import { RESTAPI, WalletContextProvider } from "./config";
import TableScore from "./components/table_score";
import logo from "./assets/images/n_logo.png";
import Nickname from "./components/nickname";

function App() {
  const [leaderboard, setLeaderboard] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLeaderboard(await RESTAPI.getLeaderboards());
    }
    fetchData();
  }, []);

  return (
    <WalletContextProvider>
      <div className="container mt-5 px-3 has-text-centered">
        <a href="http://fadedarcade.com/" className="connect_btn">
          Play More Games
        </a>

        <a href="https://hamsterjamz.com/" className="connect_btn">
          Play For Free
        </a>
      </div>

      <div>
        {leaderboard && (
          <TableScore gameName={leaderboard.name} scores={leaderboard.scores} />
        )}
      </div>

      <div className="container mt-5 px-3 has-text-centered">
        <p className="p_second">
          {" "}
          <span
            onClick={() =>
              window.open("https://start.hamsterjamz.com", "_blank")
            }
          >
            Enter Arcade
          </span>{" "}
          |{" "}
          <span
            onClick={() =>
              window.open("https://twitter.com/fadedarcadenft", "_blank")
            }
          >
            Twitter HamsterJamz
          </span>{" "}
          |{" "}
          <span
            onClick={() =>
              window.open(
                "https://securedownloads.s3.amazonaws.com/TheHamsterJamzGameHistory.pdf",
                "_blank"
              )
            }
          >
            Whitepaper
          </span>{" "}
          |{" "}
          <span
            onClick={() =>
              window.open("https://opensea.io/collection/hamsterjamz", "_blank")
            }
          >
            OpenSea
          </span>
        </p>

        <p className="powered_by">
          Powered by{" "}
          <a href="https://fadedarcade.com/" rel="noreferrer" target="_blank">
            <img src={logo} alt="FadedArcade" />
          </a>
        </p>
      </div>

      <Nickname />
    </WalletContextProvider>
  );
}

export default App;
